<template>
  <v-card elevation="0" class="pa-0 my-4 cart-item">
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col cols="11">
          <div class="d-flex align-center pa-3">
            <div class="d-block">
              <v-img
                v-if="lazyPhotoUrl"
                class="cart-item__product--image"
                :lazy-src="lazyPhotoUrl"
                :src="photoUrl"
                aspect-ratio="1"
                width="70"
                contain
              ></v-img>
              <v-img
                v-else
                class="cart-item__product--image"
                src="~@/assets/product-dafault.jpeg"
                lazy-src="~@/assets/product-dafault.jpeg"
                width="70"
                aspect-ratio="1"
              >
              </v-img>

            </div>
            <div class="mx-4">
              <div class="mb-1 cart-item__product--title">
                {{ product_name }}
              </div>
              <div v-if="product_spec" class="mb-1 cart-item__product--title">
                {{ product_spec }}
              </div>
              <div class="d-flex">
                <span v-if="isUnitSame">{{ secondTitle }}</span>
                <template v-else>
                  <span class="cart-item__product--price">{{ mainTitle }}</span>
                  <span v-if="mainTitle" class="mx-2">|</span>
                  <span>{{ secondTitle }}</span>
                </template>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="1">
          <div class="d-flex align-center pt-3 pr-3">
            <i @click="removeItem" class="ri-close-line"></i>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="px-3 py-0 mb-2"><v-divider></v-divider></div>
        </v-col>
        <v-col cols="12">
          <div class="py-2 px-3 d-flex justify-center align-center">
            <number-input
                :value="quantity"
                @change="changeHandler"
                :fixed="2"
                :rule="(count) => countRule({count, unit: shipping_unit})"
            />
            <p class="mb-0 ml-3 d-block">{{ orderCountSuffix }}</p>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="py-2 pl-3 cart-item__card--price" elevation="0" tile>
            預估小計：
          </div>
        </v-col>
        <v-col cols="8">
          <div
            class="py-2 pr-3 cart-item__card--price text-right"
            elevation="0"
            tile
          >
            {{ total }} 元
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import unitText from "@/mixins/unitText.js";
import countMixins from "@/mixins/count.js"

export default {
  mixins: [unitText, countMixins],
  props: {
    index: Number,
    value: Object,
    storeId: String,
    providerId: String,
    readApi: Function,
    handleRemoveItem: Function,
  },
  components: {
    numberInput: () => import("@/modules/base/components/numberInput.vue"),
  },
  data: () => ({
    quantity: null,
  }),
  computed: {
    item_id() {
      return this.value.item_id;
    },
    isUnitSame() {
      if (!this.value.shipping_unit) return false;
      if (!this.value.calculate_unit) return false;
      return this.value.shipping_unit === this.value.calculate_unit;
    },
    product_name() {
      return this.value.product_name;
    },
    product_spec() {
      const spec = [this.value.spec1, this.value.spec2, ...this.value.attr].filter((spec) => spec).join(" , ")
      return spec
    },
    lazyPhotoUrl() {
      const photoMain = Array.isArray(this.value.photos) ? this.value.photos[0] : this.value.photos
      return this.$helper.getPhotoUrl(photoMain, ["tiny"])
    },
    photoUrl() {
      const photoMain = Array.isArray(this.value.photos) ? this.value.photos[0] : this.value.photos
      return this.$helper.getPhotoUrl(photoMain)
    },
    price() {
      return this.value.price;
    },
    discount_config() {
      return this.value.discount_config;
    },
    order_count() {
      return this.value.quantity;
    },
    calculate_unit() {
      return this.value.calculate_unit;
    },
    shipping_unit() {
      return this.value.shipping_unit;
    },
    per_shipping_count() {
      return this.value.per_shipping_count;
    },
    orderCountSuffix() {
      if (this.isUnitSame) return this.calculate_unit;
      return this.shipping_unit;
    },
    secondTitle() {
      return this.priceTextFunc({
        unit: this.calculate_unit,
        price: this.price,
      });
    },
    mainTitle() {
      return this.perShippingCountTextFunc({
        isUnitSame: this.isUnitSame,
        shipping_unit: this.shipping_unit,
        per_shipping_count: this.per_shipping_count,
        calculate_unit: this.calculate_unit,
      });
    },
    computedQuantity() {
      return this.$helper.transformShippingCount({count: this.quantity, unit: this.shipping_unit})
    },
    total() {
      let total;

      if (this.isUnitSame) {
        total = Number(this.price) * Number(this.computedQuantity);
      } else {
        total =
          Number(this.price) *
          Number(this.computedQuantity) *
          Number(this.per_shipping_count);
      }
      return isNaN(total) ? null : this.$fixedPrice(total, 2);
    },
  },
  watch: {
    order_count: {
      immediate: true,
      handler() {
        this.quantity = this.$helper.reverseShippingCount(this.order_count, this.shipping_unit);
      },
    },
    async quantity(newQuantity, oldQuantity) {
      if (this.quantity == this.order_count) return;
      // call update cart api
      await this.updateQuantity(oldQuantity);
    },
    total: {
      immediate: true,
      handler() {
        this.$emit("changeTotal", this.total);
      },
    },
  },
  methods: {
    async removeItem() {
      this.$store.dispatch("loading/active");
      try {
        const params = { items: [this.item_id] };
        await this.$api.collection.cartApi.delete(
          this.storeId,
          this.providerId,
          params
        );
        await this.readApi();
        await this.handleRemoveItem(this.index)
        this.$snotify.success("刪除成功");
      } catch (err) {
        console.error(err);
        this.$snotify.error("刪除失敗");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    changeHandler(quantity) {
      this.quantity = quantity;
    },
    async updateQuantity(oldQuantity) {
      this.$store.dispatch("loading/active");
      try {
        const params = [{ item_id: this.item_id, quantity: this.computedQuantity }];
        await this.$api.collection.cartApi.update(
          this.storeId,
          this.providerId,
          params
        );
        await this.readApi();
        this.$snotify.success("更新成功");
      } catch (err) {
        console.error(err);
        this.$snotify.error("更新失敗");
        this.quantity = oldQuantity;
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
  },
};
</script>

<style lang="sass">
.cart-item
  background: #ffffff
  color: #122A47
  border: 1px solid #ECECEC !important
  border-radius: 5px !important
  .cart-item__product
    &--image
      border: 1px solid #ECECEC !important
      border-radius: 5px
    &--title
      font-size: 18px
      color: #000000
      font-weight: 600
    &--price
      font-size: 16px
      font-weight: 550
      color: #006DE0
  .cart-item__card--price
    font-size: 16px
    color: #006DE0 !important
</style>