<template >
  <section>
    <div v-if="stage == 0">
      <p class="mb-1">目前您的購物車中沒有商品...</p>
      <p>請單擊下面的"繼續購物"</p>
    </div>

    <div v-if="stage == 1">
      <template v-if="orderItemsNormal.length > 0 && freightPriceShowList['normal']">
        <div class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #006DE0; color: #fff;">常溫</div>
        <div v-if="freightPriceShowList['normal'].priceFreeLimit - normal_amount > 0" class="mx-3" style="color: #032F0C">
          只差{{ freightPriceShowList['normal'].priceFreeLimit - normal_amount }}元即可享有免運優惠!
        </div>
        <CartItem
          v-for="(item, index) in orderItemsNormal"
          :key="item.item_id"
          :value="item"
          :index="index"
          :storeId="storeId"
          :providerId="providerId"
          :readApi="readApi"
          :handleRemoveItem="handleRemoveItem"
          @changeTotal="getAllTypeAmount();getChildrenTotal();"
        >
        </CartItem>
      </template>
      <template v-if="orderItemsRefrigeration.length > 0 && freightPriceShowList['refrigeration']">
        <div class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #0353a7; color: #fff;">冷藏</div>
        <div v-if="freightPriceShowList['refrigeration'].priceFreeLimit - refrigeration_amount > 0" class="mx-3" style="color: #032F0C">
          只差{{ freightPriceShowList['refrigeration'].priceFreeLimit - refrigeration_amount }}元即可享有免運優惠!
        </div>
        <CartItem
          v-for="(item, index) in orderItemsRefrigeration"
          :key="item.item_id"
          :value="item"
          :index="index"
          :storeId="storeId"
          :providerId="providerId"
          :readApi="readApi"
          :handleRemoveItem="handleRemoveItem"
          @changeTotal="getAllTypeAmount();getChildrenTotal();"
        >
        </CartItem>
      </template>
      <template v-if="orderItemsFreezing.length > 0 && freightPriceShowList['freezing']">
        <div class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #002a56; color: #fff;">冷凍</div>
        <div v-if="freightPriceShowList['freezing'].priceFreeLimit - freezing_amount > 0" class="mx-3" style="color: #032F0C">
          只差{{ freightPriceShowList['freezing'].priceFreeLimit - freezing_amount }}元即可享有免運優惠!
        </div>
        <CartItem
          v-for="(item, index) in orderItemsFreezing"
          :key="item.item_id"
          :value="item"
          :index="index"
          :storeId="storeId"
          :providerId="providerId"
          :readApi="readApi"
          :handleRemoveItem="handleRemoveItem"
          @changeTotal="getAllTypeAmount();getChildrenTotal();"
        >
        </CartItem>
      </template>
    </div>

    <div v-if="stage == 2">
      <template v-if="orderItemsNormal.length > 0 && freightPriceShowList['normal']">
        <div class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #006DE0; color: #fff;">常溫</div>
        <div v-if="freightPriceShowList['normal'].priceFreeLimit - normal_amount > 0" class="mx-3" style="color: #032F0C">
          只差{{ freightPriceShowList['normal'].priceFreeLimit - normal_amount }}元即可享有免運優惠!
        </div>
        <OrderConfirmCard
          v-for="(item, index) in orderItemsNormal"
          :key="`orderItemsNormal${index}`"
          :value="createConfirmCard(item)"
        ></OrderConfirmCard>
      </template>
      <template v-if="orderItemsRefrigeration.length > 0 && freightPriceShowList['refrigeration']">
        <div class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #0353a7; color: #fff;">冷藏</div>
        <div v-if="freightPriceShowList['refrigeration'].priceFreeLimit - refrigeration_amount > 0" class="mx-3" style="color: #032F0C">
          只差{{ freightPriceShowList['refrigeration'].priceFreeLimit - refrigeration_amount }}元即可享有免運優惠!
        </div>
        <OrderConfirmCard
          v-for="(item, index) in orderItemsRefrigeration"
          :key="`orderItemsRefrigeration${index}`"
          :value="createConfirmCard(item)"
        ></OrderConfirmCard>
      </template>
      <template v-if="orderItemsFreezing.length > 0 && freightPriceShowList['freezing']">
        <div class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #002a56; color: #fff;">冷凍</div>
        <div v-if="freightPriceShowList['freezing'].priceFreeLimit - freezing_amount > 0" class="mx-3" style="color: #032F0C">
          只差{{ freightPriceShowList['freezing'].priceFreeLimit - freezing_amount }}元即可享有免運優惠!
        </div>
        <OrderConfirmCard
          v-for="(item, index) in orderItemsFreezing"
          :key="`orderItemsFreezing${index}`"
          :value="createConfirmCard(item)"
        ></OrderConfirmCard>
      </template>

      <div class="d-flex mb-5">
        <v-text-field
          class="mx-3"
          :rules="rules"
          name="name"
          hide-details="auto"
          :value="name"
          :label="$t('store.data.name')"
          :placeholder="$t('store.data.name')"
        ></v-text-field>

        <v-text-field
          class="mx-3"
          :rules="rules"
          name="phone"
          hide-details="auto"
          :value="phone"
          :label="$t('store.data.member_phone')"
          :placeholder="$t('store.data.member_phone')"
        ></v-text-field>
      </div>

      <div class="d-flex">
        <v-select
          class="mx-1"
          :rules="rules"
          dense
          outlined
          :items="citiesList"
          name="city"
          :item-value="city"
          :label="$t('data.city')"
          v-model="city"
        ></v-select>

        <v-select
          class="mx-1"
          :rules="rules"
          dense
          outlined
          :items="districtsList"
          name="district"
          :item-value="district"
          :label="$t('data.district')"
          v-model="district"
        ></v-select>
      </div>

      <v-text-field
        class="mx-3"
        :rules="rules"
        name="address"
        hide-details="auto"
        :value="address"
        :label="$t('data-receive-address')"
        :placeholder="$t('data-receive-address')"
      ></v-text-field>

      <v-textarea class="mt-8" name="comment" label="備註" outlined></v-textarea>
    </div>

    <number-bar
      v-if="stage != 0"
      readonly
      title="商品總計"
      unit="元"
      :value="amount"
      type="secondary"
      style="background-color: #000!important;"
    ></number-bar>

    <number-bar
      v-if="stage != 0 && orderItemsNormal.length > 0"
      readonly
      title="常溫運費"
      unit="元"
      :value="itemsFreightNormal"
      type="secondary"
      style="background-color: #006de0!important;"
    ></number-bar>

    <number-bar
      v-if="stage != 0 && orderItemsRefrigeration.length > 0"
      readonly
      title="冷藏運費"
      unit="元"
      :value="itemsFreightRefrigeration"
      type="secondary"
      style="background-color: #0353a7!important;"
    ></number-bar>

    <number-bar
      v-if="stage != 0 && orderItemsFreezing.length > 0"
      readonly
      title="冷凍運費"
      unit="元"
      :value="itemsFreightFreezing"
      type="secondary"
      style="background-color: #002a56!important;"
    ></number-bar>
    <number-bar
      v-if="stage != 0"
      readonly
      title="含運總計"
      unit="元"
      :value="Number(amount) + Number(itemsFreightNormal) + Number(itemsFreightRefrigeration) + Number(itemsFreightFreezing)"
      type="secondary"
      style="background-color: #3028c9!important;"
    ></number-bar>
  </section>
</template>

<script>
import ItemMixin from '@/components/form/custom/ItemMixin.js'
import amountTotal from "@/mixins/amountTotal.js"
import CartItem from "./cartItem.vue"
import OrderConfirmCard from "@/modules/base/components/order/orderConfirmCard.vue"
import numberBar from "@/modules/base/components/bar/numberBar.vue"
import discountMixins from "@/mixins/discount.js";
import citiesList from "@/config/citiesList.json"

export default {
    mixins: [ItemMixin, amountTotal, discountMixins],
    props: {
      props: Object
    },
    components: {
      CartItem,
      numberBar,
      OrderConfirmCard
    },
    data: () => ({
      rules: [
        value => !!value || '此欄位為必填',
      ],
      formKey: "info-form",
      data : [],
      me : [],
      freightPriceList : [],
      freightPriceShowList: {
        normal: {},
        refrigeration: {},
        freezing: {}
      },
      citiesList: [],
      city: '',
      district: '',
      address: '',
      name: '',
      phone: '',
      normal_amount: 0,
      refrigeration_amount: 0,
      freezing_amount: 0,
      outlying_islands: [
        '東沙島',
        '太平島',
        '琉球鄉',
        '蘭嶼鄉',
        '綠島鄉',
        '馬公市',
        '湖西鄉',
        '白沙鄉',
        '西嶼鄉',
        '望安鄉',
        '七美鄉',
        '金城鎮',
        '金寧鄉',
        '金沙鎮',
        '烈嶼鄉',
        '金湖鎮',
        '烏坵鄉',
        '南竿鄉',
        '北竿鄉',
        '莒光鄉',
        '東引鄉',
      ],
    }),
    watch : {
      propsValue: {
        immediate: true,
        deep: true,
        handler() {
            if(!this.propsValue) return ;

            this.data = this.$eagleLodash.cloneDeep(this.propsValue)
            if(!this.data || this.data.length == 0) {
              this.changeStage(0)
            }

        },
      },
      district: {
        immediate: true,
        deep: true,
        handler() {
          const area = this.outlying_islands.includes(this.district) ? 'outlying_islands' : 'main_island'
          this.freightPriceList.map(item => {
            if (item.area === area && this.freightPriceShowList[item.temperature]) {
              this.$set(this.freightPriceShowList[item.temperature], 'price', item.amount)
            }
            if (item.area === `${area}_free` && this.freightPriceShowList[item.temperature]) {
              this.$set(this.freightPriceShowList[item.temperature], 'priceFreeLimit', item.amount)
            }
          })
        },
      }
    },
    async mounted() {
      this.citiesList = 
      citiesList.map(item => item.city)

      this.freightPriceList = await this.getFreightPrice();
      this.me = await this.getMe();

      this.name = this.me.name
      this.phone = this.me.phone
      this.city = this.me.city
      this.district = this.me.district
      this.address = this.me.address
    },
    computed: {
      districtsList() {
        if (this.city.length === 0) return
        const targetCity = citiesList.find(item => {
          return item.city === this.city
        })
        return targetCity.districts.map(item => item.name)
      },
      orderItems() {
        const orderItems = this.data.map(item => {
          return {
            ...item,
            price: item.product_price
          }
        })
        return orderItems
      },
      orderItemsNormal() {
        return this.orderItems.filter(item => item.temperature === 'normal')
      },
      orderItemsRefrigeration() {
        return this.orderItems.filter(item => item.temperature === 'refrigeration')
      },
      orderItemsFreezing() {
        return this.orderItems.filter(item => item.temperature === 'freezing')
      },
      itemsFreightNormal() {
        return this.orderItemsNormal.length > 0 && this.freightPriceShowList['normal'] && (this.freightPriceShowList['normal']['priceFreeLimit'] - this.normal_amount > 0) ? this.freightPriceShowList['normal']['price'] : 0
      },
      itemsFreightRefrigeration() {
        return this.orderItemsRefrigeration.length > 0 && this.freightPriceShowList['refrigeration'] && (this.freightPriceShowList['refrigeration']['priceFreeLimit'] - this.refrigeration_amount > 0) ? this.freightPriceShowList['refrigeration']['price'] : 0
      },
      itemsFreightFreezing() {
        return this.orderItemsFreezing.length > 0 && this.freightPriceShowList['freezing'] && (this.freightPriceShowList['freezing']['priceFreeLimit'] - this.freezing_amount > 0) ? this.freightPriceShowList['freezing']['price'] : 0
      },
      storeId() {
        return this.$store.getters['member/storeId']
      },
      providerId() {
        return this.$route.params.providerId
      },
      changeStage() {
        return this.props.changeStage
      },
      stage() {
        if(!this.props) return 1
        return this.props.stage
      },
      readApi() {
        return this.props.readApi
      },
      formData() {
        return this.$store.getters[`form/${this.formKey}/data`];
      },
    },
    methods: {
      getAllTypeAmount(){
        const normalItemIdList = this.orderItemsNormal.map(v => v.item_id)
        const refrigerationItemIdList = this.orderItemsRefrigeration.map(v => v.item_id)
        const freezingItemIdList = this.orderItemsFreezing.map(v => v.item_id)
        this.normal_amount = Math.round(this.$children.reduce((arr, cur) => {
          if (cur.total && normalItemIdList.includes(cur.item_id)) arr += Number(cur.total)
          return arr
        }, 0))
        this.refrigeration_amount = Math.round(this.$children.reduce((arr, cur) => {
          if (cur.total && refrigerationItemIdList.includes(cur.item_id)) arr += Number(cur.total)
          return arr
        }, 0))
        this.freezing_amount = Math.round(this.$children.reduce((arr, cur) => {
          if (cur.total && freezingItemIdList.includes(cur.item_id)) arr += Number(cur.total)
          return arr
        }, 0))
      },
      async getMe() {
        try {
          const res = await this.$api.collection.storeApi.readByProviderAndStore(this.storeId, this.providerId)
          return res;
        } catch (err) {
          console.err(err);
          return [];
        }
      },
      async getFreightPrice() {
        try {
          const res = await this.$api.collection.providerApi.getFreightPrice(this.providerId);
          return res;
        } catch (err) {
          console.err(err);
          return [];
        }
      },
      async handleRemoveItem(index) {
        await this.$nextTick()
        await this.getChildrenTotal()
        await this.getAllTypeAmount()
      },
      createConfirmCard(item) {
        const spec = [item.spec1, item.spec2, ...item.attr].filter((spec) => spec).join(" , ")
        return {
          product_name: item.product_name,
          shipping_unit: item.shipping_unit,
          calculate_unit: item.calculate_unit,
          per_shipping_count: item.per_shipping_count,
          price: item.price,
          order_count: this.$helper.reverseShippingCount(item.quantity, item.shipping_unit),
          product_spec: spec,
        }
      },
    },
}
</script>

